<template>
  <v-row dense>
    <v-col cols="12">
      <v-data-table
        v-model="selection"
        :headers="headers"
        :items="arrayList"
        :value="arrayList"
        :items-per-page="20"
        item-key="pk"
        disable-filtering
        :loading="isLoading"
        loading-text="Loading data... Please wait"
        class="mb-5"
        :footer-props="pageOptions"
      >
        <template v-slot:item="{ item, index }">
          <tr class="text-center">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.x.toFixed(4) }}</td>
            <td>{{ item.y.toFixed(4) }}</td>
            <td>
              {{
                !item.enabled
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import PageOptions from "@/mixins/PageOptions";
export default {
  name: "DataTableGuidePins",
  mixins: [PageOptions],
  props: {
    arrayList: {
      type: Array,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selection: [],
      headers: [
        {
          text: "Row",
          align: "center",
          value: "index",
          width: "50",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Name",
          align: "center",
          value: "name",
          width: "120",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "X Coord",
          align: "center",
          value: "x",
          width: "140",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Y Coord",
          align: "center",
          value: "y",
          width: "140",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Do Not Populate",
          align: "center",
          value: "enabled",
          width: "110",
          class: "primary--text body-2 font-weight-bold",
        },
      ],
    };
  },
};
</script>
